h1 {
  margin: 0.5em 0;
  font-size: 3em;
}

h2 {
  margin: 1em 0 0.4em 0;
}

h3, h4, h5 {
  color: #666;
}

h4.alert-heading {
  margin: 0.5em 0;
}

::placeholder {
  color: #888 !important
}

nav {
  border-bottom: 1px solid #eaeff4;
  height: 90px;
  background-color: #c7f2f3;
}

nav .statuspage {
  font-size: 1.3em;
}

div.page {
  margin-top: 6em;
}

main {
  padding: 2em 4em !important;
}

div.toc {
  min-width: 26em;
}

// This prevents the table of contents from overlapping the main page content. It allows the widest image to be unobstructed.
@media (max-width: 1715px) {
  div.toc {
    display: none;
  }
}

div.toc h3 {
  margin: 0.6em 0;
}

div.toc li {
  color: #888;
  padding: 0.2em 0;
}

div.toc a {
  color: #666;
}

div.toc .component {
  position: fixed;
  top: 7em;
  right: 1em;
  width: 26em;
  padding: 1em 2em;
  background-color: #f6f6f6;
}

ul#toc {
  padding: 0 0 0 1em;
  margin: 1em 0;
  list-style-type: disc;
}

ul#toc li {
  margin: 0.3em 0;
}

div.sidebar {
  padding-right: .5em;
  min-width: 20em;
  border-right: 1px solid #eaeff4;
}

div.sidebar ul {
  list-style: none;
  padding: 0.5em 0;
  line-height: 1.9;
}

div.sidebar h3 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0;
}

div.sidebar ul a {
  color: #888;
  text-decoration: none;
  font-size: 1em;
}

div.sidebar a:hover {
  text-decoration: underline;
}

div.sidebar a.active_page {
  color: #b627a1;
  font-weight: bold;
}

div.sidebar hr {
  border-top: 1px dotted #888;
}

.sidebar-title {
  font-size: 1.8em;
  padding: 0.1em 0.5em;
  text-align: center;
}

.sidebar-title a {
  color: #b627a1;
}

.highlight {
  background-color: #555;
  padding: 30px;
  border-radius: 8px;
}

code {
  color: #b627a1;
}

pre {
  margin: 0 !important;
  white-space: pre-wrap;
}

pre code {
  color: #fff;
}

footer {
  background-color: #202020;
  color: #fff;
  font-size: 14px;
  font-weight: 200;
}

footer a, footer a:hover, footer a:visited, footer a:active {
  color: #fff;
  display: inline-block;
  margin-left: 1em;
}

table {
  @extend .table;
  margin-top: 40px;
}

table th {
  font-family: "GT Walsheim Pro Medium", sans-serif;
}

.badge-primary {
  font-size: 14px;
  line-height: 1.5;
}

h3, h4, h5 {
  margin: 1em 0;
}

.screenshot {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.datatable th, .datatable td {
  text-align: left;
  vertical-align: middle;
}

td.datatable-divider-row {
  background-color: #eaf9ff;
  text-align: left;
}

.datatable-divider-column {
  border-left: solid 1px #e8e8e8;
}

.datatable-description {
  font-style: italic;
  font-size: 0.9em;
  color: #666;
}

.datatable-data {
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  font-size: 0.9em;
}

.has-tooltip {
  display: inline-block;
  line-height: 1.2em;
  padding: 0 0.3em;
  background-color: #28bbbb;
  border-radius: 1em;
  color: #fff;
  font-size: 0.8em;
  vertical-align: top;
}

.has-tooltip:hover {
  color: #3f3f3f;
  text-decoration: none;
}

.card-persona h2 {
  margin-top: 0.3em;
}

.card-persona .card-body {
  background: #eaf9ff;
}

.card-persona .list-group-item {
  margin: 0.2em 0;
}

.card-img-top {
  padding: 1em 3em;
  border-bottom: 1px solid #eaeff4;
}

.card-note {
  width: 70%;
  margin: 0 auto;
}

.card-note .card-header {
  background-color: #f1b83e;
  font-size: 1.3em;
}


/* Auto-complete search box feature */
.aa-Label {
  display: none;
}

.aa-InputWrapper {
  padding: 0 1em;
}

.aa-Panel {
  width: 600px;
}

.search-autocomplete-item {
  border-bottom: solid 1px #eaeff4;
  padding: 0.5em;
}

.search-autocomplete-item a {
  color: black;
  text-decoration: none;
}

.search-autocomplete-item p {
  margin: 0.5em 0;
}

.search-autocomplete-url, .search-result-url {
  color: #999;
  font-size: 0.8em;
}

.search-autocomplete-item-title {
  font-weight: bold;
  color: #28bbbb;
}

/* Search page */
.ais-Hits-item {
  box-shadow: none !important;
  border-bottom: solid 1px #eaeff4;
  padding: 1.5em 0 !important;
}

.ais-search-box {
  width: 100%;
  max-width: none !important;
}

.search-result-item h5, .search-result-item p {
  margin: 0.5em 0;
}

.search-result-item p {
  margin: 0.5em 0;
}

#search-pagination {
  text-align: right;
  margin-top: 1em;
}

.ais-pagination--item__active {
  background: #28bbbb !important;
}

/* Export console */
[v-cloak] { display:none !important; }

#console-login {
  width: 50em;
  margin-top: 6em;
  border: 1px solid #eaeff4;
  padding: 0 2em;
  background-color: #f8f8f8;
}

#console-login form {
  text-align: center;
  display: block;
  margin-bottom: 1rem;
}

#console-login input {
  width: 16em;
}

#console-header h2 {
  margin:0;
}

#create-export-modal h4 {
  margin: 0;
}

#create-export-modal .modal-dialog {
  height: 900px;
}

#create-export-modal .modal-body { 
  padding: 2em 3em;
}

#create-export-modal table {
  margin: 0 0 2em 0;
}

/* Analytics console */
#analytics-console {
  font-size: 0.9em;
}

#analytics-console .multiselect__option, #analytics-console .multiselect__single {
  font-size: 1em;
}

#analytics-console .tab-pane {
  max-height: 700px;
  overflow: scroll;
}

#analytics-console table {
  margin: 0;
}

#analytics-console td, #analytics-console code {
  line-break: anywhere;
}

#analytics-console h5, #analytics-console p {
  padding-left: 0.4em;
}

#analysis-results h5 button {
  float:right;
}

#analytics-console canvas {
  height: 400px;
  margin-bottom: 4em;
}

#analytics-console .form-row {
  margin-bottom: 0.4em;
}

.form-card {
  background-color: #fafafa;
  padding: 1em 2em 0 2em;
  border: 1px solid #e8e8e8;
  margin-bottom: 2em;
  height: auto;
}

#analytics-console .nav-item {
  font-size: 1.1em;
  width: 10em;
  text-align: center;
}

#analytics-console .nav-item a {
  color:#3f3f3f;
}

#exports-table .company-name {
  font-size: 0.85em;
  text-transform: uppercase;
  color: #aaa;
}

#exports-table p {
  margin-bottom: 0.4em;
}

/* Owned social metrics page */
#owned-social-sources-index {
  background-color: #eee;
  list-style-type: none;
  padding: 1em 1em;
}
#owned-social-sources-index li {
  display: inline-block;
  margin-right: 2em;
}

.owned-social-sources-metrics th:nth-child(1) {
  width: 25%;
}

.owned-social-sources-metrics th:nth-child(2) {
  width: 20%;
}

.owned-social-sources-metrics th:nth-child(3) {
  width: 15%;
}

/* Export fields page */
.enterprise-only-label {
  color: #b627a1;
  font-size: 80%;
}