
@import "font";

$fa-font-path:"/assets/fonts";
$font-family-walsheim: "GT Walsheim Pro";
$font-family-base: $font-family-walsheim, sans-serif;
$headings-font-weight: 200;
$headings-margin-bottom: 1rm;
$gray-900: #3f3f3f;
$blue: #28bbbb;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1480px
) !default;
